var white = '#fffceb';
var black = '#11111f';
var blue = '#2d5dd7';
export default {
  fonts: {
    body: '"Crimson Text", serif'
  },
  googleFont: 'https://fonts.googleapis.com/css?family=Crimson+Text',
  colors: {
    text: black,
    background: white,
    link: blue
  },
  styles: {
    root: {
      textAlign: 'left',
      fontSize: ['1.5em', '3em']
    },
    Slide: {
      display: 'block',
      padding: '2em',
      textAlign: 'left'
    }
  }
};