import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.array.is-array";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.map";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.map";
import { match } from "@reach/router/lib/utils";
import stripPrefix from "./strip-prefix";
import normalizePagePath from "./normalize-page-path";
var pathCache = new Map();
var matchPaths = [];

var trimPathname = function trimPathname(rawPathname) {
  var pathname = decodeURIComponent(rawPathname); // Remove the pathPrefix from the pathname.

  var trimmedPathname = stripPrefix(pathname, __BASE_PATH__) // Remove any hashfragment
  .split("#")[0] // Remove search query
  .split("?")[0];
  return trimmedPathname;
};
/**
 * Set list of matchPaths
 *
 * @param {Array<{path: string, matchPath: string}>} value collection of matchPaths
 */


export var setMatchPaths = function setMatchPaths(value) {
  matchPaths = value;
};
/**
 * Return a matchpath url
 * if `match-paths.json` contains `{ "/foo*": "/page1", ...}`, then
 * `/foo?bar=far` => `/page1`
 *
 * @param {string} rawPathname A raw pathname
 * @return {string|null}
 */

export var findMatchPath = function findMatchPath(rawPathname) {
  var trimmedPathname = cleanPath(rawPathname);

  for (var _iterator = matchPaths, _isArray = Array.isArray(_iterator), _i = 0, _iterator = _isArray ? _iterator : _iterator[Symbol.iterator]();;) {
    var _ref;

    if (_isArray) {
      if (_i >= _iterator.length) break;
      _ref = _iterator[_i++];
    } else {
      _i = _iterator.next();
      if (_i.done) break;
      _ref = _i.value;
    }

    var _ref2 = _ref,
        matchPath = _ref2.matchPath,
        path = _ref2.path;

    if (match(matchPath, trimmedPathname)) {
      return normalizePagePath(path);
    }
  }

  return null;
}; // Given a raw URL path, returns the cleaned version of it (trim off
// `#` and query params), or if it matches an entry in
// `match-paths.json`, its matched path is returned
//
// E.g `/foo?bar=far` => `/foo`
//
// Or if `match-paths.json` contains `{ "/foo*": "/page1", ...}`, then
// `/foo?bar=far` => `/page1`

export var findPath = function findPath(rawPathname) {
  var trimmedPathname = trimPathname(rawPathname);

  if (pathCache.has(trimmedPathname)) {
    return pathCache.get(trimmedPathname);
  }

  var foundPath = findMatchPath(trimmedPathname);

  if (!foundPath) {
    foundPath = cleanPath(rawPathname);
  }

  pathCache.set(trimmedPathname, foundPath);
  return foundPath;
};
/**
 * Clean a url and converts /index.html => /
 * E.g `/foo?bar=far` => `/foo`
 *
 * @param {string} rawPathname A raw pathname
 * @return {string}
 */

export var cleanPath = function cleanPath(rawPathname) {
  var trimmedPathname = trimPathname(rawPathname);
  var foundPath = trimmedPathname;

  if (foundPath === "/index.html") {
    foundPath = "/";
  }

  foundPath = normalizePagePath(foundPath);
  return foundPath;
};