var blue = '#0af';
export default {
  googleFont: 'https://fonts.googleapis.com/css?family=Bowlby+One+SC',
  fonts: {
    body: '"Bowlby One SC", sans-serif'
  },
  colors: {
    text: '#dff',
    background: '#011',
    primary: blue,
    blue: blue,
    black: '#000'
  },
  fontWeights: {
    heading: 600,
    bold: 600
  },
  styles: {
    pre: {
      color: 'primary',
      bg: 'black'
    },
    code: {
      color: 'primary'
    }
  }
};