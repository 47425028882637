import "core-js/modules/es6.promise";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.promise";
import "core-js/modules/es6.object.to-string";

var support = function support(feature) {
  if (typeof document === "undefined") {
    return false;
  }

  var fakeLink = document.createElement("link");

  try {
    if (fakeLink.relList && typeof fakeLink.relList.supports === "function") {
      return fakeLink.relList.supports(feature);
    }
  } catch (err) {
    return false;
  }

  return false;
};

var linkPrefetchStrategy = function linkPrefetchStrategy(url) {
  return new Promise(function (resolve, reject) {
    if (typeof document === "undefined") {
      reject();
      return;
    }

    var link = document.createElement("link");
    link.setAttribute("rel", "prefetch");
    link.setAttribute("href", url);
    link.onload = resolve;
    link.onerror = reject;
    var parentElement = document.getElementsByTagName("head")[0] || document.getElementsByName("script")[0].parentNode;
    parentElement.appendChild(link);
  });
};

var xhrPrefetchStrategy = function xhrPrefetchStrategy(url) {
  return new Promise(function (resolve, reject) {
    var req = new XMLHttpRequest();
    req.open("GET", url, true);

    req.onload = function () {
      if (req.status === 200) {
        resolve();
      } else {
        reject();
      }
    };

    req.send(null);
  });
};

var supportedPrefetchStrategy = support("prefetch") ? linkPrefetchStrategy : xhrPrefetchStrategy;
var preFetched = {};

var prefetch = function prefetch(url) {
  return new Promise(function (resolve) {
    if (preFetched[url]) {
      resolve();
      return;
    }

    supportedPrefetchStrategy(url).then(function () {
      resolve();
      preFetched[url] = true;
    })["catch"](function () {}); // 404s are logged to the console anyway
  });
};

export default prefetch;