var white = '#ffeec1';
export default {
  googleFont: 'https://fonts.googleapis.com/css?family=Rye|Roboto+Mono',
  fonts: {
    body: '"Rye", serif',
    monospace: '"Roboto Mono", monospace'
  },
  colors: {
    text: white,
    background: 'black',
    primary: white
  },
  styles: {
    h1: {
      textTransform: 'uppercase'
    }
  }
};